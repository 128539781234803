<template>
	<div class="submit-proposal">
		<div class="submit-proposal-wrap">
			<div class="header">
				<div class="title">
					<img
						:src="'/img/icon/ArrowLeft.svg'"
						alt=""
						@click="goToHome()"
					/>
					<span>Submit Proposal</span>
				</div>
				<div class="description">
					Select a proposal category to get started
				</div>
			</div>
			<div class="content">
				<div class="content-title">COMMON ACTIONS</div>

				<div v-for="category in categories" :key="category.key">
					<div
						class="content-detail"
						@click="createProposal(category.key)"
					>
						<div>
							<img :src="'/img/icon/' + category.icon" alt="" />
						</div>
						<div>
							<div class="category-title">
								{{ category.title }}
							</div>
							<div class="category-description">
								{{ category.description }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import AppConfig from '@/App.Config.js'
var gConfig = AppConfig();

export default {
	name: 'SubmitProposal',
	components: {},
	data() {
		return {
			categories: [
				{
					key: 'map-building',
					icon: 'map-marker.svg',
					title: 'Map & Building',
					description:
						'Map expansion, Building design, Unoccupied area design, Landmark modification, etc.',
				},
				{
					key: 'on-doll',
					icon: 'on-doll.svg',
					title: 'On - Doll',
					description:
						'On-Doll balancing, Additional design, Concept proposal, etc.',
				},
				{
					key: 'item',
					icon: 'item.svg',
					title: 'Item',
					description:
						'Price change, Additional item production proposal, etc.',
				},
				{
					key: 'game',
					icon: 'game-controller.svg',
					title: 'Game',
					description:
						'Squid game balancing, Compensation related, Mini game production proposal, etc.',
				},
				{
					key: 'business',
					icon: 'business-center-sharp.svg',
					title: 'Business',
					description:
						'Metaverse performance proposal, Popular clothing brand fashion show proposal, etc.',
				},
				{
					key: 'system',
					title: 'System',
					icon: 'file-system.svg',
					description:
						'Compensation payment, abuse report, UI improvement suggestion, etc.',
				},
				{
					key: 'name-ban',
					icon: 'cancel.svg',
					title: 'Name Ban',
					description:
						'Nicknames using abusive language, containing sexual remarks, political issues etc.',
				},
				{
					key: 'etc',
					title: 'Etc',
					icon: 'etc.svg',
					description:
						'Ask community members for their opinion on an issue or topic.',
				}
			],
		}
	},
	created() {
		if(!gConfig.isProd) {
			this.categories = [...this.categories, {
				key: 'promotion',
					title: 'Promotion',
					icon: 'promotion.png',
					description:
						'프로모션 잇힝. 나를 설명해줘',
			}]
		}
	},
	methods: {
		goToHome() {
			if (this.$route.query.backRoute && window.history.state.back) {
				this.$router.back()
			} else {
				this.$router.push({
					name: 'Home',
				})
			}
		},
		createProposal(category) {
			const acc = this.$store?.state?.wallet?.accounts[0]
			if (!acc) {
				this.$router.push({
					name: 'Catalyst',
					query: {
						nextName: 'CreateProposal',
						nextParams: category,
					},
				})
				// this.$store.dispatch('showLoginPopup', true)
			} else {
				this.$router.push({
					name: 'CreateProposal',
					params: { category: category },
					query: {
						backRoute: this.$route.name,
					},
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.submit-proposal {
	font-family: 'Montserrat';
	background: white;
	min-height: calc(100vh - 230px);
	.submit-proposal-wrap {
		width: 100%;
		max-width: 1180px;
		height: auto;
		margin: 0 auto;
		padding: 30px 20px;
		.header {
			.title {
				display: flex;
				gap: 10px;
				align-items: center;
				img {
					width: 32px;
					height: 32px;
					cursor: pointer;
				}
				span {
					font-size: 36px;
					font-weight: 700;
					line-height: 1.5;
					color: #000;
				}
			}
			.description {
				font-size: 14px;
				color: #000;
				padding-left: 45px;
			}
		}
		.content {
			margin-top: 30px;
			padding-left: 45px;
			.content-title {
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				margin-bottom: gREm(16);
			}
			.content-detail {
				border: 1px solid #eef2ff;
				border-radius: 8px;
				display: flex;
				align-items: center;
				padding: gREm(16);
				margin-bottom: gREm(12);
				gap: gREm(20);
				cursor: pointer;
				&:hover {
					border: 1px solid #6c38ef;
					box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
					transform: translateY(gREm(-5));
					transition: 0.2s;
				}
				.category-title {
					font-weight: 600;
					font-size: 16px;
					line-height: 150%;
					color: #454348;
				}
				.category-description {
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
					color: #454348;
				}
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
	.submit-proposal-wrap {
		padding: 20px !important;
		.header {
			.title {
				flex-direction: column;
				gap: 0px !important;
				align-items: flex-start !important;
			}
			.description {
				padding-left: 0 !important;
			}
		}
		.content {
			padding-left: 0 !important;
		}
	}
}
</style>
