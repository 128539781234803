<template>
	<div class="title-box">
		<HeaderTab />
	</div>
	<div class="submit-page">
		<SubmitProposal />
	</div>
	<Foot :theme="'white'" />
</template>

<script>
import SubmitProposal from '@/components/Submit/SubmitProposal.vue'
import Foot from '@/components/FOOT.vue'
import HeaderTab from '@/components/HeaderTab.vue'

export default {
	name: 'SubmitPage',
	components: {
		SubmitProposal,
		Foot,
		HeaderTab
	},
	mounted() {
		document.querySelector('.gnb-box-bg').scrollIntoView()
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.title-box {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: gREm(204);
	background-image: url('../assets/img/dao/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	.title-wrap {
		@include FLEXV(center, center);
		width: auto;
		height: gREm(95);
		margin-top: gREm(215);
		.title {
			height: auto;
			width: gREm(322);
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff, 600);
			text-align: center;
		}
		.sub-title {
			height: auto;
			width: auto;
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff);
			text-align: center;
		}
	}
}

.submit-page {
	font-family: 'Montserrat';
	background: white;
}
</style>
